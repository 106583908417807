<script setup lang="ts">
import type { IMissingDataResponse } from '~/composables/useAPI/types'
import { createDashboardPortfolioURI } from '@dashboard'

const props = defineProps<IMissingDataResponse>()

const companyId = getCurrentDivisionId()

const tooltip = computed(() => {
  if (props.count)
    return `${props.count} leases are incomplete and they are not counted in this chart.`
  return 'Some leases are incomplete and they are not counted in this chart.'
})
</script>

<template>
  <VTooltip
    v-if="link || count"
    class="inline-flex"
    :delay="10"
    placement="top"
    :distance="12"
    :auto-hide="true"
  >
    <Button
      :to="createDashboardPortfolioURI(companyId, link)"
      color="link"
      size="sm"
      icon="info"
      class="text-white"
    >
      {{ count ?? 'View' }} uncounted leases
    </Button>

    <template #popper>
      <div class="text-xs">{{ tooltip }}</div>
    </template>
  </VTooltip>
</template>
